
import {ActionTree, GetterTree, Module, MutationTree} from "vuex";
import {RootState} from "@/store/types";
import { _axios as axios } from "@/plugins/axios";
import {BACKEND_API_URL} from "@/shared/consts";
import { Campaign, CampaignsState } from "./types";
import { Sorting, TableQuery } from "@/modules/shared/utils/TableQuery";
import { ProductTypes, TimeTypes } from "../../../store/types";
import moment from "moment";

export const emptyCampaignData: Campaign = {
  attachments: [],
  campaignName: '',
  issuerId: null,
  data: null,
  landingPageUrl: '',
  investmentValue: null,
  investmentValueStep: null,
  investmentPaymentValue: {
    min: 0,
    max: null,
  },
  startDate: null,
  endDate: null,
  type: 'LOAN',
  imageId: null,
  productCodeFormat: null,
  productCodePrefix: null,
  products: [
    {
      name: null,
      type: ProductTypes.loan,
      data: {
        currency: 'PLN',
        timeType: TimeTypes.fixedRepaymentDate,
        fixedRepaymentDate: null,
        simpleOptions: {
          interest: null,
          interestPayment: 'Monthly',
        },
        duration: null,
        interestType: 'Fixed',
        enabledAdvancedOptions: false,
        interestPaidWithin: 0,
        interestCalculationMethod: '30/365',
        debtor: null,
      }
    }
  ],
};

export const state: CampaignsState = {
  campaignsTableIsBusy: false,
  campaignsTable: {
    items: [],
    totalCount: 0,
    perPage: 10,
    query: new TableQuery(10),
  },
  leadForm: {
    fields: [],
    data: null,
  },
  campaignData: emptyCampaignData,
  translations: {
    PL: {
      dictionaries: {
        title: {
          'Mr.': 'Pan',
          'Mrs.': 'Pani'
        },
        documentType: {
          ID_CARD: 'dowód osobisty',
          PASSPORT: 'paszport'
        },
        country: {
          PL: 'Polska'
        }
      },
      validations: {
        required: 'Pole wymagane',
        minLength: 'Minimalna długość to',
        maxLength: 'Maksymalna długość to',
        email: 'Pole musi być poprawnym adresem email',
        strongPassword: 'Hasło jest zbyt słabe',
        sameAsPassword: 'Hasło musi być identyczne',
        minValue: 'Minimalna wartość to',
        maxValue: 'Maksymalna wartość to',
        wrongDate: 'Niepoprawna data',
        zipCode: 'Podaj poprawny kod pocztowy',
        bankAccount: 'Niepoprawny nr rachunku bankowego',
        checked: 'Pole musi zostać zaznaczone',
        phone: 'Niepoprawny nr telefonu',
        url: 'Niepoprawny adres url',
        iban: 'Niepoprawny nr rachunku bankowego'
      },
      inviteInvestor: 'Zaproś klienta',
      daysLeft: 'Dni do końca',
      investmentReach: 'Zebrane środki',
      remainingValue: 'Pozostało do pozyskania',
      invite: 'Zaproś',
      target: 'Cel inwestycyjny',
      duration: 'Czas trwania',
      interestPA: 'Zwrot z inwestycji P.A.',
      interestPayment: 'Płatności odsetkowe',
      campaignDetails: 'Szczegóły kampanii',
      progress: 'Postęp',
      invest: 'Zainwestuj',
      interestPaymentOptions: {
        Monthly: 'miesięczne',
        Quarterly: 'kwartalne',
        'Semi-annually': 'półroczne',
        Annually: 'roczne',
        'At Maturity': 'na zapadalność'
      },
      dates: {
        one: 'miesiąc',
        multiple: 'miesiące',
        decimal: 'miesiąca'
      },
      durations: {
        year: {
          one: 'rok',
          multiple: 'lata',
          other: 'lat'
        },
        quarter: {
          one: 'kwartał',
          multiple: 'kwartały',
          other: 'kwartałów'
        },
        month: {
          one: 'miesiąc',
          multiple: 'miesiące',
          other: 'miesięcy',
          decimal: 'miesiąca'
        },
        day: {
          one: 'dzień',
          multiple: 'dni',
          other: 'dni'
        }
      },
      generalData: 'Dane ogólne',
      detailedData: 'Dane szczegółowe',
      addressData: 'Dane adresowe',
      firstName: 'Imię',
      surname: 'Nazwisko',
      crmData: 'Dane CRM',
      investmentPotential: 'Potencjał inwestycyjny',
      investmentKnowledge: 'Wiedza inwestycyjna',
      industry: 'Branża',
      notes: 'Notatki',
      investmentDetails: 'Szczegóły inwestycji',
      yourInvestment: 'Twoja inwestycja',
      nextStep: 'Następny krok',
      cancel: 'Powrót',
      title: 'Tytuł',
      middleName: 'Drugie imię',
      birthDay: 'Data urodzenia',
      fathersName: 'Imię ojca',
      mothersName: 'Imię matki',
      phoneNo: 'Nr telefonu',
      identificationNo: 'PESEL',
      documentType: 'Rodzaj dokumentu',
      documentNo: 'Seria i nr dokumentu',
      documentExpiryDate: 'Data ważności dokumentu',
      documentCountry: 'Kraj wydania dokumentu',
      countryOfTaxResidence: 'Kraj rezydencji podatkowej',
      nationality: 'Obywatelstwo',
      birthPlace: 'Miejsce urodzenia',
      bankAccount: 'Rachunek bankowy',
      bankAccountName: 'Nazwa banku',
      swiftCode: 'Kod SWIFT',
      residentialAddress: 'Adres zamieszkania',
      street: 'Ulica',
      houseNo: 'Nr budynku',
      flatNo: 'Nr mieszkania',
      city: 'Miasto',
      zipCode: 'Kod pocztowy',
      country: 'Kraj',
      mailingAddress: 'Adres pocztowy',
      sameAsResidential: 'Taki sam jak zamieszkania',
      investRequestSucceed: 'Dziękujemy za Twoje zgłoszenie.',
      mailSent: 'Na podany adres e-mail zostało wysłane potwierdzenie zapisu',
      goToPanel: 'Przejdź do panelu',
      selectAtLeastOneIndustry: 'Wybierz co najmniej jedną branżę'
    },
    EN: {
      dictionaries: {
        title: {
          'Mr.': 'Mr.',
          'Mrs.': 'Mrs.'
        },
        documentType: {
          ID_CARD: 'ID card',
          PASSPORT: 'passport'
        },
        country: {
          PL: 'Poland'
        }
      },
      validations: {
        required: 'This field is required',
        minLength: 'Minimum length is',
        maxLength: 'Maximum length is',
        email: 'This field must be valid email',
        strongPassword: 'Password is too weak',
        sameAsPassword: 'Passwords must be same',
        minValue: 'Minimum value is',
        maxValue: 'Maximum value is',
        wrongDate: 'Invalid date',
        zipCode: 'Provide correct zip code',
        bankAccount: 'Incorrect bank account number',
        checked: 'This field must be checked',
        phone: 'Invalid phone number',
        url: 'Invalid url',
        iban: 'Invalid Bank Account number'
      },
      inviteInvestor: 'Invite investor',
      invite: 'Invite',
      daysLeft: 'Days left',
      investmentReach: 'Raised funds',
      remainingValue: 'Remaining funds',
      target: 'Target',
      duration: 'Duration',
      interestPA: 'Interest P.A.',
      interestPayment: 'Interest payment',
      campaignDetails: 'Campaign details',
      progress: 'Progress',
      invest: 'Invest',
      interestPaymentOptions: {
        Monthly: 'monthly',
        Quarterly: 'quaterly',
        'Semi-annually': 'semi-annually',
        Annually: 'annually',
        'At Maturity': 'at maturity'
      },
      dates: {
        one: 'month',
        multiple: 'months',
        decimal: 'months'
      },
      durations: {
        year: {
          one: 'year',
          multiple: 'years',
          other: 'years'
        },
        quarter: {
          one: 'quarter',
          multiple: 'quarters',
          other: 'quarters'
        },
        month: {
          one: 'month',
          multiple: 'months',
          other: 'months',
          decimal: 'months'
        },
        day: {
          one: 'day',
          multiple: 'days',
          other: 'days'
        }
      },
      generalData: 'General data',
      detailedData: 'Detailed data',
      addressData: 'Address data',
      firstName: 'First name',
      surname: 'Surname',
      crmData: 'CRM Data',
      investorPotential: 'Investor potential',
      investorKnowledge: 'Investor knowledge',
      industry: 'Industry',
      notes: 'Notes',
      investmentDetails: 'Investment details',
      yourInvestment: 'Your investment',
      nextStep: 'Next step',
      cancel: 'Cancel',
      title: 'Title',
      middleName: 'Middle name',
      birthDay: 'Birth day',
      fathersName: 'Fathers name',
      mothersName: 'Mothers name',
      phoneNo: 'Phone no.',
      identificationNo: 'Identification no.',
      documentType: 'Document type',
      documentNo: 'Document no.',
      documentExpiryDate: 'Document expiry date',
      documentCountry: 'Document country',
      countryOfTaxResidence: 'Country of tax residence',
      nationality: 'Nationality',
      birthPlace: 'Birth place',
      bankAccount: 'Bank account',
      bankAccountName: 'Bank account name',
      swiftCode: 'SWIFT code',
      residentialAddress: 'Residential address',
      street: 'Street',
      houseNo: 'House no.',
      flatNo: 'Flat no.',
      city: 'City',
      zipCode: 'Zip code',
      country: 'Country',
      mailingAddress: 'Mailing address',
      sameAsResidential: 'Same as residential',
      investRequestSucceed: 'Invest request succeed',
      mailSent: 'We have sent a link confirming your identity to the email address provided',
      goToPanel: 'Go to panel',
      selectAtLeastOneIndustry: 'Select at least one industry'
    }
  },
}

export const getters: GetterTree<CampaignsState, RootState> = {
  leadFormFields(state) {
    return JSON.parse(JSON.stringify(state.leadForm.fields));
  },
  leadFormData(state) {
    return JSON.parse(JSON.stringify(state.leadForm.data));
  },
  getCampaignData(state) {
    return JSON.parse(JSON.stringify(state.campaignData));
  },
  getCampaignsTableItems(state) {
    return JSON.parse(JSON.stringify(state.campaignsTable.items));
  },
  getCampaignsTableQuery(state) {
    return JSON.parse(JSON.stringify(state.campaignsTable.query));
  },
  getCampaignsTablePerPage(state) {
    return JSON.parse(JSON.stringify(state.campaignsTable.perPage));
  },
  getCampaignsTableFiltersQuery(state) {
    return JSON.parse(JSON.stringify(state.campaignsTable.query.filters));
  },
  translations (state) {
    return state.translations
  },
}

export const mutations: MutationTree<CampaignsState> = {
  setLeadFormFields(state, payload) {
    state.leadForm.fields = payload;
  },
  setLeadFormData(state, payload) {
    state.leadForm.data = payload;
  },
  setCampaignData(state, campaignData: Campaign) {
    if(campaignData.campaignName === '') {
      campaignData.campaignName = null;
    }

    if(campaignData.landingPageUrl === '') {
      campaignData.landingPageUrl = null;
    }

    if(campaignData.products[0].name === '') {
      campaignData.products[0].name = null;
    }

    if(!campaignData.investmentPaymentValue.min) {
      campaignData.investmentPaymentValue.min = 0;
    }

    if(!campaignData.investmentPaymentValue.max) {
      campaignData.investmentPaymentValue.max = null;
    }

    state.campaignData = campaignData;
  },
  clearCampaignData(state) {
    state.campaignData = emptyCampaignData;
  },
  setCampaignsTableItems(state, payload: {items: Array<any>, totalCount: number}): void {
    state.campaignsTable = { ...state.campaignsTable, items: payload.items, totalCount: payload.totalCount};
  },
  setCampaignsTableQuery(state, payload: TableQuery): void {
    state.campaignsTable.query = payload;
  },
  setCampaignsTableBusy(state,payload: boolean): void {
    state.campaignsTableIsBusy = payload;
  },
  setCampaignsTablePerPage(state, payload: number): void {
    state.campaignsTable.perPage = payload;
  },
  setCampaignsTableSortingQuery(state, payload: Sorting): void {
    state.campaignsTable.query.sorting = payload;
    state.campaignsTable.query.offset = 0;
  },
  setCampaignsTableFiltersQuery(state, payload: string) {
    state.campaignsTable.query.filters = payload;
  },
}

export const actions: ActionTree<CampaignsState, RootState> = {
  async getCampaigns({state,commit}, filtersQuery?: string): Promise<Array<any>> {
    const queryString = state.campaignsTable.query.getStringQuery();
    filtersQuery = filtersQuery ? filtersQuery : '';
    const queryData = await axios.get(`${BACKEND_API_URL}/marketplace/legal-entity/campaigns${queryString}${filtersQuery}`, { headers: { 'x-total-count': true} });

    const payload: {items: Array<any>, totalCount: string} = { items: queryData.data, totalCount: queryData.headers['x-total-count'] }
    commit('setCampaignsTableItems', {
      items: payload.items,
      totalCount: payload.totalCount,
    });

    return payload.items;
  },
  async getAllCampaignsByType({state, commit}, type: string) {
    const { data } = await axios.get(`${BACKEND_API_URL}/marketplace/legal-entity/campaigns?limit=9999999&filters[type][0][value]=${type}&filters[status][0][operator]=eq`);

    return data;
  },
  async getAllCampaigns({state, commit}, type: string) {
    let { data } = await axios.get(`${BACKEND_API_URL}/marketplace/legal-entity/campaign-choices`)

    if (type) {
      data = data?.filter((el: any) => el?.type?.toLowerCase() === type?.toLowerCase())
    }

    data = data?.filter((el: any) => el?.status?.toLowerCase() === 'active')

    return data
  },
  async getLeadForm({state, commit}) {
    const { data } = await axios.get(`${BACKEND_API_URL}/marketplace/lead-form`);
    commit('setLeadFormData', data);

    return data;
  },
  async getLeadFormParams({state, commit}) {
    const { data } = await axios.get(`${BACKEND_API_URL}/marketplace/lead-form-parameters`);
    commit('setLeadFormFields', data);

    return data;
  },
  async postLeadForm({state}, payload: { name: string, required: boolean }[]) {
    const { data } = await axios.post(`${BACKEND_API_URL}/marketplace/lead-form`, {
      parameters: payload
    });

    return data;
  },
  async getCampaign({state}, campaignId: string) {
    const { data } = await axios.get(`${BACKEND_API_URL}/marketplace/legal-entity/campaign/${campaignId}`);

    const endDate = data.endDate ? `${moment(data.endDate).utc().startOf('day').format('YYYY-MM-DD')}T23:59:59+00:00` : null
    
    data.endDate = endDate;

    return data;
  },
  async postCampaign({state}, campaignData: Campaign) {
    delete campaignData.image;
    delete campaignData.imagePath;

    const endDate = campaignData?.endDate ? `${moment(campaignData.endDate).startOf('day').format('YYYY-MM-DD')}T23:59:59+00:00` : null;

    campaignData.endDate = endDate;

    const { data } = await axios.post(`${BACKEND_API_URL}/marketplace/legal-entity/campaign`, campaignData);

    return data;
  },
  async putCampaign({state}, payload: { campaignId: string, campaignData: Campaign }) {
    delete payload.campaignData.image;
    delete payload.campaignData.imagePath;

    const endDate = payload.campaignData?.endDate ? `${moment(payload.campaignData.endDate).startOf('day').format('YYYY-MM-DD')}T23:59:59+00:00` : null;

    payload.campaignData.endDate = endDate;

    const { data } = await axios.put(`${BACKEND_API_URL}/marketplace/legal-entity/campaign/${payload.campaignId}`, payload.campaignData);

    return data;
  },
  async markCampaignAsDraft({state}, campaignId: string) {
    const { data } = await axios.patch(`${BACKEND_API_URL}/marketplace/legal-entity/campaign/${campaignId}/mark-as-draft`);

    return data;
  },
  async markCampaignAsActive({state}, campaignId: string) {
    const { data } = await axios.patch(`${BACKEND_API_URL}/marketplace/legal-entity/campaign/${campaignId}/activate`);

    return data;
  },
  async archiveCampaign({state}, campaignId: string) {
    const { data } = await axios.patch(`${BACKEND_API_URL}/marketplace/legal-entity/campaign/${campaignId}/archive`);

    return data;
  },
  async finishCampaign({state}, campaignId: string) {
    const { data } = await axios.patch(`${BACKEND_API_URL}/marketplace/legal-entity/campaign/${campaignId}/finish`);

    return data;
  },
  async notifyBrokers({state}, campaignId: string) {
    const { data } = await axios.post(`${BACKEND_API_URL}/marketplace/legal-entity/campaign/${campaignId}/notify-brokers`);

    return data;
  },
  async deleteCampaignProduct({state}, payload: { campaignId: string, productId: string }) {
    const { data } = await axios.delete(`${BACKEND_API_URL}/marketplace/legal-entity/campaign/${payload.campaignId}/product/${payload.productId}`);

    return data;
  },
  async addCampaignToNetwork({state}, payload: { networkId: string, id: string }) {
    const { data } = await axios.post(`${BACKEND_API_URL}/marketplace/legal-entity/broker-network/${payload.networkId}/campaign`, { campaignId: payload.id });

    return data;
  },
  async deleteCampaignFromNetwork({state}, payload: { networkId: string, id: string }) {
    const { data } = await axios.delete(`${BACKEND_API_URL}/marketplace/legal-entity/broker-network/${payload.networkId}/campaign/${payload.id}`);

    return data;
  },
  async assignProductToCampaign({state}, payload: { productId: string, campaignId: string }) {
    const { data } = await axios.patch(`${BACKEND_API_URL}/marketplace/legal-entity/product/${payload.productId}/assign-campaign`, { campaignId: payload.campaignId });

    return data;
  }
}

export const campaigns: Module<CampaignsState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

