





































import Vue from 'vue'
import Component from 'vue-class-component'
import {Emit, Prop, Watch} from "vue-property-decorator";
import {BSpinner} from "bootstrap-vue";
import { PlusCircleIcon, ChevronRightIcon, ChevronLeftIcon, ChevronTopIcon, XMarkIcon } from '@/assets/icons/heroicons/heroicons';

@Component({
  components: { BSpinner, PlusCircleIcon, ChevronRightIcon, ChevronLeftIcon, ChevronTopIcon, XMarkIcon }
})
export default class SygniRectButton extends Vue {

  active: boolean = false;

  @Prop({ default: true }) legacyStyling: boolean;
  @Prop({default: false}) selectable!: boolean;
  @Prop({default: true}) hoverable!: boolean;
  @Prop({default: false}) plusIcon!: boolean;
  @Prop({default: false}) arrowIcon!: boolean;
  @Prop({default: false}) arrowIconLeft!: boolean;
  @Prop({default: false}) disabled!: boolean;
  @Prop({default: false}) value!: boolean;
  @Prop({default: false}) loading: boolean;
  @Prop({default: ''}) tooltip: string;

  @Watch('value') onValueChange(): void {
      if(this.value !== this.active){
        this.active = !this.active;
        this.active ? this.$el.classList.add('sygni-rounded-button--active') : this.$el.classList.remove('sygni-rounded-button--active');
      }
  }
  get buttonClass(){
    const classes: Array<string> = [];
    if(this.disabled){
      classes.push('inactive');
    }
    classes.push('sygni-rect-button');
    if(this.hoverable){classes.push('sygni-rect-button--hoverable');}
    return classes.join(' ');
  }

  @Emit() click() {
    if(!this.disabled && !this.loading){
      return true;
    }
  }

  toggleActive(): void {
    this.click();
    if(!this.selectable) return;
    this.active = !this.active;
    this.active ? this.$el.classList.add('sygni-rect-button--active') : this.$el.classList.remove('sygni-rect-button--active');
  }

  mounted() {
  }
}
