



















































import Vue from 'vue'
import Component, {mixins} from 'vue-class-component'
import { Prop } from "vue-property-decorator";
import {SelectOption} from "@/store/types";
import SygniInputError from "@/components/inputs/SygniInputError.vue";
import {SygniValidation} from "@/shared/mixins/ValidationMixin";
import Multiselect from 'vue-multiselect'
import SygniCheckbox from "@/components/inputs/SygniCheckbox.vue";
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import SygniRectButton from "@/components/buttons/SygniRectButton.vue";
import {MD5} from "@/shared/md5";
import _ from 'lodash';

@Component({
  components: { SygniCheckbox, SygniInputError, Multiselect, SygniRoundedButton, SygniRectButton }
})
export default class SygniSelect extends mixins(Vue, SygniValidation) {
  @Prop({default: false}) showLegalEntityName: boolean;
  @Prop({default: true}) legacyStyling: boolean;
  @Prop() label!: String;
  @Prop() value!: string;
  @Prop() limit: number;
  @Prop() options!: SelectOption[];
  @Prop({default: 'label'}) optionsLabel!: string;
  @Prop({default: 'label'}) trackBy!: string;
  @Prop({default: 'List is empty'}) emptyText: string;
  @Prop({default: false}) searchable!: boolean;
  @Prop({default: false}) disabled: boolean;
  @Prop({default: null}) nativeTooltip: string;
  @Prop({default: false}) preselectFirst!: boolean;
  @Prop({default: false}) preserveSearch!: boolean;
  @Prop({default: true}) clearOnSelect!: boolean;
  @Prop({default: false}) addOptionBtn!: boolean;
  @Prop({default: 'Add new counterparty'}) addOptionBtnText!: string;
  @Prop({default: true}) internalSearch!: boolean;
  @Prop({default: false}) placeholderAsValue: boolean;
  @Prop({default: false}) selectOnlyOption: boolean;
  @Prop({default: 'below'}) direction: string;
  @Prop({default: null}) testId: string;

  get optionValue(): SelectOption {
    if(this.options?.length > 0){
      return this.options.find( option => option.value === this.value);
    } else {
      return null;
    }
  }

  get formattedOptions(): any[] {
    const optionKey: string = this.optionsLabel ?? 'label';
    if (this.options && this.options[0] && this.options[0][optionKey]) {
      this.options.forEach(option => {
        if(option[optionKey]) {
          option[optionKey][0].toUpperCase() + option[optionKey].slice(1, option[optionKey].length)
        }
      });
      return this.options;
    } else {
      return []
    }
  }

  get emptyClass() {
    const value = this.formattedOptions.find(el => el.value == this.value);
    return !value ? 'empty' : '';
  }

  searchChangeAction = _.debounce((search: string) => {
    this.$emit('searchChange', search)
  }, 200);

  handleInput(option: SelectOption): any {
    this.$emit('input', option?.value);
  }

  addOption() {
    const hash = MD5((this.$refs.multiSelect as any).search);
    this.$emit('addOption', { label: (this.$refs.multiSelect as any).search, value: hash });
    (this.$refs.multiSelect as any).toggle()
  }

  highlightedText(option: string, data: any) {
    if(option && (this.$refs.multiSelect as any)?.search) {
      const index = option.toLowerCase().indexOf((this.$refs.multiSelect as any).search.toLowerCase());
      if (index >= 0) {
        option = option.substring(0, index) + "<span class='highlighted-text'>" + option.substring(index, index + (this.$refs.multiSelect as any).search.length) + "</span>" + option.substring(index + (this.$refs.multiSelect as any).search.length);
      }
    }

    if(data) {
      let additionalString = ` ${data.tin ? `| ${data.tin}` : ''}`;
      const index = additionalString.toLowerCase().indexOf((this.$refs.multiSelect as any).search.toLowerCase());
      if (index >= 0) {
        additionalString = additionalString.substring(0, index) + "<span class='highlighted-text'>" + additionalString.substring(index, index + (this.$refs.multiSelect as any).search.length) + "</span>" + additionalString.substring(index + (this.$refs.multiSelect as any).search.length);
      }
      option += `${additionalString} | ${data.address?.street ? data.address.street : ''} ${data.address?.houseNumber ? data.address.houseNumber : ''}${data.address?.flatNumber ? '/' + data.address.flatNumber : ''} ${data.address?.zipCode ? ', ' + data.address.zipCode : ''} ${data.address?.city ? data.address.city : ''}`;
    }

    return option;
  }

  mounted() {
    if(this.selectOnlyOption) {
      if(this.options.length === 1) {
        this.$emit('input', this.options[0]?.value);
      }
    }
  }

  clear(event:any){
    event;
  }

  removeKey(object: any, propName: string){
    return this.$options.filters.removeKey(object, propName);
  }
}


