import { render, staticRenderFns } from "./LoaderIcon.vue?vue&type=template&id=6483f928&scoped=true&"
import script from "./LoaderIcon.vue?vue&type=script&lang=ts&"
export * from "./LoaderIcon.vue?vue&type=script&lang=ts&"
import style0 from "./LoaderIcon.vue?vue&type=style&index=0&id=6483f928&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6483f928",
  null
  
)

export default component.exports