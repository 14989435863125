var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('button', {
    class: [_vm.buttonClass, !_vm.legacyStyling ? 'tw-theme' : ''],
    attrs: {
      "disabled": _vm.disabled
    },
    on: {
      "click": function click($event) {
        return _vm.toggleActive();
      }
    }
  }, [_vm.plusIcon || _vm.arrowIconLeft || _vm.arrowIconTop ? _c('div', {
    staticClass: "icon-left"
  }, [_vm.plusIcon ? _c('PlusCircleIcon', {
    staticClass: "svg-icon"
  }) : _vm._e(), _vm.arrowIconLeft ? _c('ChevronLeftIcon', {
    staticClass: "svg-icon"
  }) : _vm._e(), _vm.arrowIconTop ? _c('ChevronTopIcon', {
    staticClass: "svg-icon"
  }) : _vm._e()], 1) : _vm._e(), _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      value: {
        customClass: _vm.tooltipCustomClass
      },
      expression: "{ customClass: tooltipCustomClass }",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "text",
    attrs: {
      "title": _vm.tooltip
    }
  }, [!_vm.loading ? _vm._t("default") : _vm._e(), _vm.loading ? [_c('b-spinner', {
    staticClass: "mx-0.5",
    attrs: {
      "type": "grow"
    }
  }), _c('b-spinner', {
    staticClass: "mx-0.5",
    attrs: {
      "type": "grow"
    }
  }), _c('b-spinner', {
    staticClass: "mx-0.5",
    attrs: {
      "type": "grow"
    }
  })] : _vm._e()], 2), _vm.arrowIcon || _vm.active ? _c('div', {
    staticClass: "icon-right"
  }, [_vm.arrowIcon ? _c('ChevronRightIcon', {
    staticClass: "svg-icon"
  }) : _vm._e(), _vm.active ? _c('XMarkIcon', {
    staticClass: "svg-icon"
  }) : _vm._e()], 1) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }