
























import { MenuItem } from '@/modules/genprox/store/types';
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator';

@Component
export default class GenproxMenu extends Vue {
  @Prop({ default: [] }) menus: MenuItem[]
  openedMenu: string = null

  get formattedMenus() {
    return this.menus?.map((menuItem: MenuItem) => {

      if (menuItem?.children?.length > 0) {
        let routeFound = false

        menuItem?.children?.forEach((childMenuItem: MenuItem) => {
          if (childMenuItem?.route === '/investor') {
            if (this.$route?.fullPath === '/investor') {
              childMenuItem.isActive = true
              routeFound = true
            } else {
              childMenuItem.isActive = false
              routeFound = false
            }
          } else {
            childMenuItem.isActive = !!(this.$route?.fullPath?.includes(childMenuItem?.route))
            if (childMenuItem.isActive) {
              routeFound = true
            }
          }
        })

        menuItem.isActive = routeFound

      } else {
        menuItem.isActive = !!(this.$route?.fullPath?.includes(menuItem?.route))
      }

      return menuItem
    })
  }

  getMenuHeight(menu: MenuItem) {
    if (this.openedMenu == menu?.label) {
      return `${45 * menu?.children?.length + 20}px`
    } else {
      return '0px'
    }
  }

  toggleActiveMenu(menuLabel: string) {
    this.openedMenu = (this.openedMenu !== menuLabel) ? menuLabel : null
  }

  setActiveMenu() {
    const activeMenu = this.formattedMenus?.find((menuItem: MenuItem) => menuItem?.isActive)
    if (activeMenu?.children?.length > 0) {
      this.openedMenu = activeMenu?.label
    }
  }

  @Watch('formattedMenus', { deep: true }) onFormattedMenusChange() {
    this.setActiveMenu()
  }

  mounted() {
    this.setActiveMenu()
  }
}
